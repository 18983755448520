import { Fragment } from 'react'
import ActiveLink from './active-link'
import Image from 'next/image'
import Link from 'next/link'
import classnames from 'classnames'
import airShaperLogo from '../public/assets/images/logo.svg'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

const links = [
  {
    href: '/pricing',
    className: 'position-relative',
    label:
      'Pricing <span class="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-warning">new</span></span>',
  },
  {
    label: 'Cases',
    children: [
      { href: '/validation', label: 'Validation' },
      { href: '/cases', label: 'Analysis' },
      { href: '/cases/optimization/page/1', label: 'Optimization' },
      { href: '/sample-projects', label: 'Sample Projects' },
    ],
  },
  { href: '/videos/page/1', label: 'Videos' },
  { href: '/blog/page/1', label: 'Blog' },
  { href: '/contact', label: 'Contact', className: 'border-0' },
]

export default function Navigation({ isCase }) {
  return (
    <>
      <div
        className={classnames('header', {
          'position-absolute l-0 r-0': isCase,
        })}
      >
        <Navbar className="navbar navbar-dark navbar-main" expand="lg">
          <Link href="/" passHref legacyBehavior>
            <Navbar.Brand className="navbar-brand justify-content-start">
              <Image
                src={airShaperLogo}
                width="188"
                eager="true"
                height="50"
                alt="AirShaper Logo"
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              {links.map(({ href, label, children, className }, i) => (
                <Fragment key={i}>
                  {!children && (
                    <ActiveLink activeClassName="active" href={href}>
                      <Nav.Link
                        className={className}
                        href={href}
                        dangerouslySetInnerHTML={{ __html: label }}
                      ></Nav.Link>
                    </ActiveLink>
                  )}
                  {children && (
                    <NavDropdown title={label} id="basic-nav-dropdown">
                      {children.map(({ href, label }, j) => (
                        <Fragment key={`${i}-${j}`}>
                          <ActiveLink activeClassName="active" href={href}>
                            <NavDropdown.Item href={href}>
                              {label}
                            </NavDropdown.Item>
                          </ActiveLink>
                        </Fragment>
                      ))}
                    </NavDropdown>
                  )}
                </Fragment>
              ))}
              <a
                href="https://app.airshaper.com"
                className="btn btn-sm btn-white me-lg-3 mb-2 mb-lg-0 btn-app shadow"
                target="_blank"
                rel="noopener noreferrer"
              >
                Log In
              </a>
              <a
                href="https://app.airshaper.com/users/sign_up"
                className="btn btn-sm btn-white btn-app shadow"
                target="_blank"
                rel="noopener noreferrer"
              >
                Sign Up
              </a>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    </>
  )
}
