import { useState } from 'react'
import Link from 'next/link'
import CookieConsent from 'react-cookie-consent'
import { useCookies } from 'react-cookie'
import dynamic from 'next/dynamic'
const Drift = dynamic(() => import('react-driftjs'))

export default function Consent() {
  const [cookies] = useCookies(['airshaper_consent'])
  const [consent, setConsent] = useState(
    cookies.airshaper_consent && cookies.airshaper_consent === 'true'
  )

  return (
    <>
      {consent && (
        <Drift
          appId="v8tszuzfevrv"
          style={{
            left: 0,
          }}
        />
      )}
      <CookieConsent
        containerClasses={'shadow-sm border'}
        location="bottom"
        buttonText="OK"
        enableDeclineButton
        declineButtonText="Opt-out"
        cookieName="airshaper_consent"
        onAccept={() => setConsent(true)}
        onDecline={() => setConsent(false)}
        buttonWrapperClasses={'clearfix w-100'}
        buttonStyle={{
          color: '#fff',
          backgroundColor: '#00bbf0',
          fontSize: '12px',
          fontWeight: 'bold',
          margin: '5px',
          float: 'right',
        }}
        declineButtonStyle={{
          color: '#fff',
          backgroundColor: '#ff0000',
          fontSize: '12px',
          fontWeight: 'normal',
          margin: '5px',
          float: 'left',
        }}
        style={{
          background: '#fff',
          color: '#000',
          bottom: '20px',
          left: '10px',
          width: '300px',
          marginBottom: '10px',
        }}
        contentStyle={{
          flex: 'inherit',
          margin: '5px',
        }}
      >
        We use cookies
        <span style={{ fontSize: '12px', display: 'block' }}>
          to improve user experience.{' '}
          <Link href="/privacy-policy">More Info</Link>
        </span>
      </CookieConsent>
    </>
  )
}
